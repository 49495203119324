<template>
  <div>
    <h1 class="mt-2">{{ user.display_name }}</h1>
    <p class="h6" v-if="user.pronouns">({{ user.pronouns }})</p>
    <avatar class="avatar" :image="user.avatar" size="xlarge" />
    <div class="mt-3">
      <div>
        <strong v-if="userOccupation"
          >{{ user.org_detail }} at {{ user.org }}</strong
        >
      </div>
      <div>
        <strong>{{ location }}</strong>
      </div>
      <span class="verified-badge" v-if="user.verified">Verified</span>
    </div>
    <div class="about" v-if="user.about">
      <h2>About Me</h2>
      <div class="about-body">{{ user.about }}</div>
    </div>
    <div class="tag-container">
      <app-tag-section
        title="Skills & Interests"
        :values="skillInterestList"
        v-if="skillInterestList.length > 0"
      ></app-tag-section>
      <app-tag-section
        title="Values"
        :values="valueList"
        v-if="valueList.length > 0"
      ></app-tag-section>
    </div>
  </div>
</template>
<script>
import Avatar from "../../app/components/avatars/Avatar.vue";
import TagSection from "../../app/components/tags/TagSection";
import sendRequest from "../../app/utils/send-request-helper";

export default {
  components: {
    avatar: Avatar,
    "app-tag-section": TagSection,
  },
  data() {
    return {
      // this is only used when props value are not available
      skillInterestList: [],
      valueList: [],
    };
  },
  async mounted() {
    if (!this.skillInterests || !this.values) {
      const userId = this.user.id;
      const skillList = await this.getUserSkills(userId);
      const interestList = await this.getUserInterests(userId);
      const valueList = await this.getUserValues(userId);
      this.skillInterestList = skillList.concat(interestList);
      this.valueList = valueList;
    } else {
      this.skillInterestList = this.skillsInterests;
      this.valueList = this.values;
    }
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    skillsInterests: {
      type: Array,
    },
    values: {
      type: Array,
    },
  },
  computed: {
    location() {
      let msg = [];
      if (this.user.location && this.user.location.city) {
        msg.push(this.user.location.city);
      }
      if (this.user.location && this.user.location.state) {
        msg.push(this.user.location.state);
      }
      return msg.join(", ");
    },
    userOccupation() {
      let info = "";
      info += this.user.org_details ? `, ${this.user.org_details}` : "";
      info +=
        this.user.org && this.user.org !== "__SKIPPED__"
          ? ` at ${this.user.org}`
          : "";
      return info;
    },
  },
  methods: {
    async getUserSkills(userId) {
      try {
        const response = await sendRequest({
          url: `/relationships/has-skill?id=${userId}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user skills",
          itemName: "user skills",
        });
        return response.map((skill) => skill.value);
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user skills."
        );
      }
    },
    async getUserValues(userId) {
      try {
        const response = await sendRequest({
          url: `/relationships/has-value?id=${userId}`,
          method: "GET",
          isAuth: false,
          requestLabel: "getting user values",
          itemName: "user value",
        });
        return response.map((value) => value.value);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user values."
        );
      }
    },
    async getUserInterests(userId) {
      try {
        const response = await sendRequest({
          url: `/relationships/has-interest?id=${userId}`,
          method: "GET",
          isAuth: false,
          labelRequest: "getting user interests",
          itemName: "user interest",
        });
        return response.map((value) => value.value);
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting user interests."
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.verified-badge {
  display: inline-block;
  background-color: $inqliGreen;
  width: 100px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 15px;
}
.profile-image {
  margin: 10px;
  width: 200px;
  border-radius: 50%;
}
.verified-badge {
  padding: 6px 14px;
  font-size: 12px;
  background: #c9eee1;
  color: rgb(23, 23, 90);
  margin: 6px;
}
.about {
  margin: auto;
  margin-top: 30px;
  &-body {
    text-align: center;
  }
}
.tag-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.avatar {
  margin: auto;
}
</style>
