<template>
  <div class="tag-section-container">
    <h2 v-if="title">{{ title }}</h2>
    <div class="tags">
      <app-tag
        v-for="(value, index) in values"
        :key="`${value}-${index}`"
        :value="value"
      ></app-tag>
    </div>
  </div>
</template>

<script>
import Tag from "./Tag";

export default {
  props: {
    title: {
      type: String,
    },
    values: {
      type: Array,
    },
  },
  components: {
    appTag: Tag,
  },
};
</script>

<style lang="scss" scoped>
.tag-section-container {
  margin-bottom: 20px;
  margin-top: 20px;
  .tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
