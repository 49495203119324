<template>
  <span class="tag rounded-pill">{{ value }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.tag {
  display: inline;
  padding: 10px 16px;
  font-size: 13px;
  background: white;
  -webkit-box-shadow: 0px 4px 20px rgba(26, 60, 68, 0.07);
  -moz-box-shadow: 0px 4px 20px rgba(26, 60, 68, 0.07);
  box-shadow: 0px 4px 20px rgba(26, 60, 68, 0.07);
  color: $inqliNavy;
  margin: 6px;
}
</style>
