var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", { staticClass: "mt-2" }, [
        _vm._v(_vm._s(_vm.user.display_name))
      ]),
      _vm.user.pronouns
        ? _c("p", { staticClass: "h6" }, [
            _vm._v("(" + _vm._s(_vm.user.pronouns) + ")")
          ])
        : _vm._e(),
      _c("avatar", {
        staticClass: "avatar",
        attrs: { image: _vm.user.avatar, size: "xlarge" }
      }),
      _c("div", { staticClass: "mt-3" }, [
        _c("div", [
          _vm.userOccupation
            ? _c("strong", [
                _vm._v(
                  _vm._s(_vm.user.org_detail) + " at " + _vm._s(_vm.user.org)
                )
              ])
            : _vm._e()
        ]),
        _c("div", [_c("strong", [_vm._v(_vm._s(_vm.location))])]),
        _vm.user.verified
          ? _c("span", { staticClass: "verified-badge" }, [_vm._v("Verified")])
          : _vm._e()
      ]),
      _vm.user.about
        ? _c("div", { staticClass: "about" }, [
            _c("h2", [_vm._v("About Me")]),
            _c("div", { staticClass: "about-body" }, [
              _vm._v(_vm._s(_vm.user.about))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "tag-container" },
        [
          _vm.skillInterestList.length > 0
            ? _c("app-tag-section", {
                attrs: {
                  title: "Skills & Interests",
                  values: _vm.skillInterestList
                }
              })
            : _vm._e(),
          _vm.valueList.length > 0
            ? _c("app-tag-section", {
                attrs: { title: "Values", values: _vm.valueList }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }